import {useEffect} from 'react';
import {z} from 'zod';
import {Button} from '#/app/components/Button';
import {Container} from '#/app/components/Container';
import {Image} from '#/app/components/Image';
import * as gtag from '#/app/helpers/gtag.client';
import {useAdvancedFetcher} from '#/app/helpers/useAdvancedFetcher';

export const letstalkInputSchema = z.object({
  email: z.coerce.string().email('Please enter a valid email address'),
});

export const YouCanToo = () => {
  const {handleChange, handleSubmit, isFormDirty, isSubmitting, ...fetcher} = useAdvancedFetcher({
    key: 'letstalk',
    schema: letstalkInputSchema,
  });
  // @ts-expect-error
  const status = isFormDirty ? undefined : fetcher.data?.status;
  const errors = fetcher.data?.errors;
  const hasErrors = (errors?.formErrors || []).length > 0 || Object.values(errors?.fieldErrors ?? {}).some((error) => !!error);

  useEffect(() => {
    gtag.event({action: 'form_error', errors: JSON.stringify(errors), form_id: 'letstalk'});
  }, [errors]);

  return (
    <div className="w-full bg-gray-100 pb-20 pt-24">
      <Container className="flex flex-col space-x-0 space-y-12 lg:flex-row lg:space-x-12 ">
        <div className="mx-auto w-10/12 space-y-8 md:w-8/12 lg:w-6/12">
          <h3 className="mb-4 leading-tight">
            You too can reduce costs and improve efficiency with <em>Ufleet</em>
          </h3>
          <div className="text-lg text-gray-600 sm:text-xl">
            Join thousands of businesses that rely on Ufleet to:
            <ul className="mt-4 list-disc space-y-2">
              <li className="ml-12">plan and optimize delivery routes</li>
              <li className="ml-12">manage and empower drivers</li>
              <li className="ml-12">enhance customer experience</li>
              <li className="ml-12">make data-driven business decisions</li>
            </ul>
          </div>
          <p className="text-xl text-gray-600">
            We’d love to learn about your challenges.
            <br />
            Leave your email and we’ll get back to you.
          </p>
          <fetcher.Form action="/api/contact" id="letstalk" method="post">
            <div className="-ml-1 flex">
              <input
                className="w-64 rounded-l-xl border-2 border-gray-800 text-lg text-gray-700 placeholder:text-gray-400 focus:border-gray-500 focus:outline-none focus:ring-0 sm:w-72"
                name="email"
                onChange={handleChange}
                placeholder="my@email.com"
                type="email"
              />
              <div className="[&>button]:rounded-l-none">
                <Button color="darkGray" onClick={handleSubmit} type="submit">
                  Let's talk
                </Button>
              </div>
            </div>
            <div className="my-2 font-medium text-green-600">
              {!hasErrors && status === 'ok' && (
                <div className="my-2 font-medium text-green-600">Thanks for dropping by. We will get back to you in the next 24 hours!</div>
              )}
              {hasErrors && <div className="text-red-500">{errors?.fieldErrors?.email}</div>}
            </div>
          </fetcher.Form>
        </div>
        <div className="flex items-center justify-center lg:w-7/12">
          <Image
            alt="ufleet dashboard and ufleet driver app screenshots"
            className="w-10/12 lg:w-full"
            src="/features-control-center-3.png"
          />
        </div>
      </Container>
    </div>
  );
};
