import {Link} from '@remix-run/react';
import {Container} from '#/app/components/Container';
import {YouCanToo} from '#/app/components/YouCanToo';
import {FEATURES} from '#/app/content';

export const Footer = () => {
  return (
    <>
      <YouCanToo />
      <footer>
        <div className="flex w-full bg-gray-200 py-16 md:py-32">
          <Container className="flex flex-col space-y-12 text-base text-gray-600 md:flex-row md:space-x-16 md:space-y-0">
            <div className="w-full md:w-3/12">
              <div className="group -ml-2 inline-block pr-2 text-4xl font-semibold text-gray-800 hover:cursor-pointer hover:text-gray-700">
                <span className="text-gray-800 group-hover:text-gray-700">⬢</span> ufleet
              </div>
              <p className="mt-4 text-lg">
                Ufleet is a complete enterprise grade route planning and customer experience management platform for the last mile delivery.
              </p>
            </div>
            <div className="grid w-full grid-cols-12 gap-4 md:w-9/12">
              <div className="col-span-6 space-y-2 md:col-span-3">
                <div className="mb-4 font-bold text-gray-700">FEATURES</div>
                {FEATURES.map((v) => (
                  <Link className="_nav block hover:text-gray-900 hover:underline" key={v.link} to={v.link}>
                    {v.name}
                  </Link>
                ))}
              </div>
              <div className="col-span-6 space-y-2 md:col-span-3">
                <Link className="mb-4 block font-bold text-gray-700 hover:underline" to="/ufleet-alternatives">
                  COMPARE
                </Link>
                {['Onfleet', 'Routific', 'Circuit', 'Tookan', 'DispatchTrack', 'eLogii', 'Route4Me'].map((v) => (
                  <Link
                    className="_nav block hover:text-gray-900 hover:underline"
                    key={v}
                    to={`/${v.toLowerCase()}-alternative-ufleet-vs-${v.toLowerCase()}`}
                  >
                    {v}
                  </Link>
                ))}
              </div>
              <div className="col-span-6 space-y-2 md:col-span-3">
                <div className="mb-4 font-bold text-gray-700">COMPANY</div>
                <Link className="_nav block hover:text-gray-900 hover:underline" to="/blog">
                  Blog
                </Link>
                <Link className="_nav block hover:text-gray-900 hover:underline" to="/customers">
                  Customers
                </Link>
                <Link className="_nav block hover:text-gray-900 hover:underline" to="/pricing">
                  Plans &amp; Pricing
                </Link>
                <Link className="_nav block hover:text-gray-900 hover:underline" to="/calculator">
                  ROI Calculator
                </Link>
                <Link className="_nav block hover:text-gray-900 hover:underline" to="/demo">
                  Demo
                </Link>
                <div className="mb-4 pt-6 font-bold text-gray-700">SUCCESS STORIES</div>
                <Link className="_nav block hover:text-gray-900 hover:underline" to="/case-studies/obi">
                  OBI Poland
                </Link>
                <Link className="_nav block hover:text-gray-900 hover:underline" to="/case-studies/devin">
                  Devin
                </Link>
              </div>
              <div className="col-span-6 space-y-8 md:col-span-3">
                <div className="space-y-2">
                  <div className="mb-4 font-bold text-gray-700">SUPPORT</div>
                  <a className="_cta block hover:text-gray-900 hover:underline" href="mailto:contact@ufleet.io">
                    Contact
                  </a>
                </div>
                <div>
                  <div className="mb-4 font-bold text-gray-700">LEGAL</div>
                  <div className="flex flex-col space-y-2">
                    <Link className="_nav hover:text-gray-900 hover:underline" to="/privacy-policy">
                      Privacy
                    </Link>
                    <Link className="_nav hover:text-gray-900 hover:underline" to="/terms-of-use">
                      Terms of Use
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-span-12 mt-8 font-bold text-gray-700 md:mt-16">GET IN TOUCH</div>
              <div className="col-span-6 leading-relaxed md:col-span-3">
                <span className="mb-2 inline-block font-semibold text-gray-700">New York</span>
                <br />
                5 Union Square West
                <br />
                New York, NY 10003
                <br />
                United States
                <br />
                <a className="_cta mt-2 inline-block italic hover:text-gray-900" href="tel:0013329006322">
                  +1 332 900-6322
                </a>
              </div>
              <div className="col-span-6 leading-relaxed md:col-span-3">
                <span className="mb-2 inline-block font-semibold text-gray-700">Geneva</span>
                <br />
                Rue du Vieux-Billard 2
                <br />
                1205 Genève
                <br />
                Switzerland
                <br />
                <a className="_cta mt-2 inline-block italic hover:text-gray-900" href="tel:0041228860188">
                  +41 22 886-0188
                </a>
              </div>
              <div className="col-span-6 leading-relaxed md:col-span-3">
                <span className="mb-2 inline-block font-semibold text-gray-700">Berlin</span>
                <br />
                Torstraße 182
                <br />
                10115 Berlin
                <br />
                Germany
                <br />
                <a className="_cta mt-2 inline-block italic hover:text-gray-900" href="tel:00493025559882">
                  +49 30 2555-9882
                </a>
              </div>
              <div className="col-span-6 leading-relaxed md:col-span-3">
                <span className="mb-2 inline-block font-semibold text-gray-700">Sofia</span>
                <br />
                11 Slaveykov Sqr
                <br />
                1000 Sofia
                <br />
                Bulgaria
                <br />
                <span className="mt-2 inline-block">R&amp;D Office</span>
              </div>
            </div>
          </Container>
        </div>
        <div className="w-full bg-gray-300 py-8 text-gray-700">
          <Container className="flex items-center justify-between">© {new Date().getFullYear()} Ufleet. (All rights reserved.)</Container>
        </div>
      </footer>
    </>
  );
};
